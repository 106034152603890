import { Component, AfterViewInit } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

declare var ElementQueries: any;

import { error, success, info, warning } from 'toastr';

export class BaseComponent implements AfterViewInit {

    constructor() {
    }

    ngAfterViewInit() {
    }

    readErrorResponse(errorResponse: HttpErrorResponse): string {
        if (console && console.log) {
            console.log(errorResponse);
        }
        if (errorResponse) {
            if (errorResponse.status == 0) { return "Error: Server Offline"; }
            if (errorResponse.status == 401) { return "Error: Unauthorized"; }
            if (typeof errorResponse.error == "string") {
                var msg = JSON.parse(errorResponse.error)
                if (msg && msg.message) {
                    return msg.message;
                }
            } else if (typeof errorResponse.error == "object" && errorResponse.error && (errorResponse.error.message || errorResponse.error.Message)) {
                return (errorResponse.error.message || errorResponse.error.Message);
            }
            else if (errorResponse.status == 500) { return "Error: Internal Server Error";}
            else if (errorResponse.status != null) {
                return `Unexpected Error: Code ${errorResponse.status}`;
            }
        }
        return "Unexpected Error";
    }

    errorAlert(msg: string): void {
        error(msg);
    }

    successAlert(msg: string): void {
        success(msg);
    }

    infoAlert(msg: string): void {
        info(msg);
    }

    warningAlert(msg: string): void {
        warning(msg);
    }
}