import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, FormGroup, FormControl, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { AuthenticationService } from './../../../services/authentication.service';
import { FormBaseComponent } from './../../shared/form-base.component';
import { LayoutService } from './../../../services/layout.service';


@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent extends FormBaseComponent implements OnInit, OnDestroy {
    success: boolean;
    
    constructor(private router: Router, private readonly authService: AuthenticationService, private layoutService: LayoutService) {
        super();
        this.success = false;
    }

    ngOnInit(): void {
        //init form
        this.form = new FormGroup({
            emailAddress: new FormControl('', Validators.required)
        });
    }

    submitForm(): void {
        this.formSubmitted = true;
        if (this.form.valid) {
            this.form.disable();
            this.authService.resetPassword(this.form.value.emailAddress)
                .subscribe(
                    (success: void) => {
                        this.form.enable();
                        this.success = true;
                        this.layoutService.clearAlert();                        
                    },
                    (error: any) => {    
                        this.form.enable();
                        this.errorAlert(this.readErrorResponse(error));
                    }
                );
        }
    }

    ngOnDestroy(): void {
    }
}
