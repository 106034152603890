import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TextMaskModule } from "angular2-text-mask";

// Shared
import { AlertsComponent } from "../components/shared/alerts/alerts.component";
import { PhoneNumberComponent } from '../components/shared/phone-number/phone-number.component';
import { FooterSectionComponent } from "../components/shared/footer/footer-section.component";
import { HeaderSectionComponent } from "../components/shared/header/header-section.component";
import { PaginationComponent } from '../components/shared/pagination/pagination.component';
import { SortToggleComponent } from '../components/shared/pagination/sort-toggle.component';

//directives
import { DateValueAccessor } from '../directives/date-value-accessor.directive';
import { UnmaskDirective } from '../directives/unmask.directive';
import { HtmlOutlet } from '../directives/html-outlet';
import { ModernDateValidator } from '../directives/modern-date-validator.directive';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule
    ],
    declarations: [
        AlertsComponent,
        DateValueAccessor,
        UnmaskDirective,
        ModernDateValidator,
        PhoneNumberComponent,
        FooterSectionComponent,
        HeaderSectionComponent,
        PaginationComponent,
        SortToggleComponent,
        HtmlOutlet
    ],
    providers: [
    ],
    exports: [
        AlertsComponent,
        DateValueAccessor,
        TextMaskModule,
        UnmaskDirective,
        ModernDateValidator,
        PhoneNumberComponent,
        FooterSectionComponent,
        HeaderSectionComponent,
        PaginationComponent,
        SortToggleComponent,
        FormsModule,
        HtmlOutlet
    ]
})

export class SharedModule { }