import { Injectable, OnInit, OnDestroy  } from "@angular/core"; 
import { Subject } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { Observable } from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Injectable()
export class LayoutService {

    constructor() {
        //auto hide alerts after 10 seconds
        this.globalAlert$.pipe(debounceTime(10000)).subscribe((result) => { this.globalAlertSource.next(null); });
    }

///-- Global Loading Status
    private loadingSource = new Subject<boolean>();

    loading$ = this.loadingSource.asObservable();

    loading(bool: boolean) {
        this.loadingSource.next(bool);
    }


///-- Global Alerts
    private globalAlertSource = new BehaviorSubject<string>(null);

    globalAlert$ = this.globalAlertSource.asObservable();

    globalAlert(msg: string) {
        this.globalAlertSource.next(msg);
    }

    clearAlert() {
        this.globalAlertSource.next(null);
    }
}