import { Component } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

import { BaseComponent } from './base.component';

export class FormBaseComponent extends BaseComponent  {
    public form: FormGroup;
    formSubmitted: boolean = false;    

    invalidForm(form: FormGroup = this.form) {
        return form.invalid && this.formSubmitted;
    }    

    getFormControl(fieldName: string, form: FormGroup = this.form): FormControl {
        return <FormControl>form.get(fieldName);
    }  

    invalidField(fieldName: string, form: FormGroup = this.form): boolean {
        return this.getFormControl(fieldName, form).invalid && this.formSubmitted;
    }

    hasError(fieldName: string, errorName: string, waitForSubmit = true, form: FormGroup = this.form) {        
        let control = this.getFormControl(fieldName, form);
        if ((!waitForSubmit || this.formSubmitted) && control.hasError(errorName)) {
            return control.errors[errorName];
        }
        return false;
    }

    hasErrorBesides(fieldName: string, errorName: string, waitForSubmit = true, form: FormGroup = this.form) {       
        let control = this.getFormControl(fieldName, form);
        if ((!waitForSubmit || this.formSubmitted) && control && control.errors) {
            return Object.keys(control.errors).some(function (error) {
                return error !== errorName
            });
        }
        return false;
    }

    hasFormError(errorName: string, waitForSubmit: boolean = true, form: FormGroup = this.form) {
        return form.hasError(errorName) && (!waitForSubmit || this.formSubmitted);
    }

}