import { Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { FormsModule, FormControl, FormGroup, Validators, ValidationErrors, ReactiveFormsModule, EmailValidator, FormArray, AbstractControl, ValidatorFn } from '@angular/forms';

//--Taken from here https://github.com/angular/angular/blob/master/packages/forms/src/validators.ts
const EMAIL_REGEXP =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;


@Injectable()
export class ValidationService {

    constructor() {
    }

    public areTheSame(fieldNameOne: string, fieldNameTwo: string, validationErrorName: string): ValidatorFn {
        return (group: FormGroup): { [key: string]: any } => {
            let field1 = group.get(fieldNameOne).value;
            let field2 = group.get(fieldNameTwo).value;

            if (field1 && field1.length > 0 && field2 && field2.length > 0 && field1 === field2) {
                return null;
            }
            //do this so we can name the validation error
            var err = {};
            err[validationErrorName] = true;
            return err;
        };
    }

    public cannotMatch(cantBeValue: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const forbidden = control.value == cantBeValue;
            return forbidden ? { 'cannotMatch': { value: control.value } } : null;
        };
    }

    public greaterThan(fieldNameOne: string, fieldNameTwo: string, validationErrorName: string): ValidatorFn {
        return (group: FormGroup): { [key: string]: any } => {
            let field1 = group.get(fieldNameOne).value;
            let field2 = group.get(fieldNameTwo).value;

            //at least one field does not have a value, so nothing to compare
            if (field1 == null || field1 == undefined
                || field2 == null || field2 == undefined) {
                return null;
            }

            if (field1 > field2) {
                return null
            }

            //do this so we can name the validation error
            var err = {};
            err[validationErrorName] = true;
            return err;
        };
    }

    public greaterThanOrEqual(fieldNameOne: string, fieldNameTwo: string, validationErrorName: string): ValidatorFn {
        return (group: FormGroup): { [key: string]: any } => {
            let field1 = group.get(fieldNameOne).value;
            let field2 = group.get(fieldNameTwo).value;

            //at least one field does not have a value, so nothing to compare
            if (field1 == null || field1 == undefined
                || field2 == null || field2 == undefined) {
                return null;
            }

            if (field1 >= field2) {
                return null
            }

            //do this so we can name the validation error
            var err = {};
            err[validationErrorName] = true;
            return err;
        };
    }

    public atLeastOneRequired(validationErrorName: string, ...fieldNames: string[]): ValidatorFn {
        return (group: FormGroup): { [key: string]: any } => {
            if (fieldNames == null || fieldNames == undefined) {
                return null;
            }

            for (let fieldName of fieldNames) {
                if (fieldName != null
                    && fieldName != undefined) {

                    let fieldValue = group.get(fieldName).value;
                    if (fieldValue != null
                        && fieldValue != undefined
                        && `${fieldValue}`.length > 0) {
                        //--One of the fields has a value
                        return null;
                    }
                }
            }

            //--None of the fields has a value, so report a validation error using the given name
            var err = {};
            err[validationErrorName] = true;
            return err;
        }
    };

    public delimitedEmails(delimeter: string = ';'): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (control.value == null || control.value.length === 0) {
                return null;
            }

            var emails = control.value.split(delimeter);
            for (let i = 0; i < emails.length; i++) {
                var email = emails[i];
                if (email != null && email.length !== 0 && !EMAIL_REGEXP.test(email.trim())) {
                    return { 'delimitedEmail': true };
                }
            }

            return null;
        };
    }

    public pastMonthYear(monthFieldName: string, yearFieldName: string, validationErrorName: string): ValidatorFn {
        return (group: FormGroup): { [key: string]: any } => {
            let month = group.get(monthFieldName).value;
            let year = group.get(yearFieldName).value;

            //at least one field does not have a value, so nothing to compare
            if (month == null || month == undefined
                || year == null || year == undefined) {
                return null;
            }

            var now = new Date();
            //--Searching on a past year OR a previous month in this year
            if (now.getFullYear() > year
                || (now.getFullYear() == year && now.getMonth() + 1 > month)) {
                return null;
            }

            //do this so we can name the validation error
            var err = {};
            err[validationErrorName] = true;
            return err;
        };
    }

    public modernDate(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (control.value == undefined
                || control.value == null
                || control.value == "") {
                return null;
            }

            if (control.value instanceof Date) {
                if ((control.value as Date).getFullYear() < 1900) {
                    return { 'modernDate': { value: control.value } };
                }
            }

            return null;
        };
    }

    public dateInMonthYear(dateFieldName: string, monthFieldName: string, yearFieldName: string, validationErrorName: string): ValidatorFn {
        return (group: FormGroup): { [key: string]: any } => {
            let month = group.get(monthFieldName).value;
            let year = group.get(yearFieldName).value;
            let dateValue = group.get(dateFieldName).value;

            //at least one field does not have a value, so nothing to compare
            if (month == null || month == undefined
                || year == null || year == undefined
                || !dateValue) {
                return null;
            }
            
            var date = new Date(dateValue);

            if (date.getFullYear() != year || date.getMonth() != (month-1))
            {
                var err = {};
                err[validationErrorName] = true;
                return err;
            }

            return null;
        };
    }
}