// angular
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

// -------------- Page Components --------------------

// App
import { AppComponent } from "./components/app/app.component";

// Public
import { HomeComponent } from "./components/public/home/home.component";
import { LoginComponent } from "./components/public/login/login.component";
import { LogoutComponent } from "./components/public/login/logout.component";
import { ForgotPasswordComponent } from "./components/public/forgot-password/forgot-password.component";
import { ForgotPasswordConfirmComponent } from "./components/public/forgot-password/forgot-password-confirm.component";
import { NotFoundComponent } from "./components/public/errors/not-found.component";
import { AccessDeniedComponent } from "./components/public/errors/access-denied.component";
import { UnauthenicatedSectionComponent } from "./components/public/home/unauthenticated-section.component";

//interceptor
import { AuthInterceptor } from "./interceptors/auth.interceptor";

//services
import { BaseService } from "./services/base.service";
import { LayoutService } from "./services/layout.service";
import { AuthenticationService } from "./services/authentication.service";
import { CurrentUserService } from "./services/current-user.service";
import { ValidationService } from "./services/validation.service";
import { ControlDataService } from "./services/control-data.service";

//global styles
import "../assets/scss/style.scss";

//third party
import "hammerjs";


//modules
import { AppRoutingModule } from "./modules/app-routing.module";
import { SharedModule } from "./modules/shared.module";

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        LogoutComponent,
        ForgotPasswordComponent,
        ForgotPasswordConfirmComponent,
        NotFoundComponent,
        AccessDeniedComponent,
        UnauthenicatedSectionComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        SharedModule
    ],
    providers: [
        BaseService,
        LayoutService,
        AuthenticationService,
        CurrentUserService,
        ControlDataService,
        ValidationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },

    ]
})
export class AppModule { }
