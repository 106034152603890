import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CurrentUserService } from './../services/current-user.service';
import { UserRole } from '../models/authentication/roles.enum';
import { CanActivateChild } from '@angular/router';

export abstract class RoleAuthGuard implements CanActivate, CanActivateChild {
    
    constructor(protected router: Router, protected currentUserService: CurrentUserService) {
    }

    abstract getAllowedRoles(): string[];

    canActivate(): boolean {

        if (!this.currentUserService.isLoggedIn()) {
            // not logged in so redirect to login page
            this.router.navigate(['/']);
            return false;
        }

        var allowedRoles = this.getAllowedRoles() || [];
        var blocked = allowedRoles.some(value => {
            return !this.currentUserService.isInRole(value);
        });

        if (blocked) {
            this.router.navigate(['/access-denied'])
            return false;
        }

        return true;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate();
    }
}