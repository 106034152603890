import { Validator, AbstractControl, NG_VALIDATORS } from "@angular/forms";
import { Directive } from "@angular/core";
import { ValidationService } from "../services/validation.service";

@Directive({
    selector: '[modernDate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ModernDateValidator, multi: true }]
})
export class ModernDateValidator implements Validator {

    constructor(private readonly validationService: ValidationService) {
    }

    validate(c: AbstractControl): { [key: string]: any; } {
        return this.validationService.modernDate()(c);
    }
}