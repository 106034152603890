import { Component } from '@angular/core';
import { BaseComponent } from './../../shared/base.component';

@Component({
    selector: 'access-denied',
    templateUrl: './access-denied.component.html'
})
export class AccessDeniedComponent extends BaseComponent {
    constructor() {
        super();
    }
}
