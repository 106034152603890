import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError  } from "rxjs";
import {catchError, flatMap} from "rxjs/operators"
import { Router } from "@angular/router";

import { CurrentUserService } from './../services/current-user.service';
import { ITokenDetails } from "../models/authentication/token-details.model";
import { AuthenticationService } from "../services/authentication.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private currentTokenRefresh: Observable<ITokenDetails>;
    private refreshingToken: boolean = false;

    constructor(private inj: Injector, private router: Router) {
    }

    //adds jwt header to requests and refreshes jwt token when it expires
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentUserService = this.inj.get(CurrentUserService);
        let nextReq = req;

        //add jwt auth header to requests
        if (currentUserService.isLoggedIn()) {
            nextReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + currentUserService.getToken().token) });
        }        
        return next.handle(nextReq)
            //catch failed responses
            .pipe(catchError((error: any) => { 

                //if the the response was a 401 and the user has a refresh token then try to refresh their auth
                if (error instanceof HttpErrorResponse && error.status === 401 && currentUserService.getToken() && currentUserService.getToken().refreshToken) {

                    //get authservice through injector to avoid cyclic dependency error https://github.com/angular/angular/issues/18224
                    let authService = this.inj.get(AuthenticationService);

                    //Allow only 1 refresh call at a time. Reuse the same promise on subsequent requests
                    if (!this.refreshingToken)
                    {
                        this.refreshingToken = true;
                        var refreshToken = currentUserService.getToken().refreshToken;
                        this.currentTokenRefresh = authService.refresh(refreshToken);
                    }

                    return this.currentTokenRefresh
                        .pipe(flatMap((data: ITokenDetails) => { //flatmap to avoid the subscribe

                            this.refreshingToken = false;

                            //if auth was successful then redo the original request, otherwise redirect to homepage
                            if (currentUserService.isLoggedIn()) {
                                let newReq = req.clone({
                                    headers: req.headers.set('Authorization', 'Bearer ' + currentUserService.getToken().token),
                                });
                                return next.handle(newReq); //retry with new token
                            }
                            else {
                                this.router.navigate(['/login']); //redirect to login page
                                return throwError(error);
                            }
                        }));
                }
                else {
                    return throwError(error);
                }
            }));
    }
}