import { Component, Input, EventEmitter, Output, SimpleChanges, OnChanges } from "@angular/core";
import { BaseComponent } from "../base.component";
import {FormControl,FormGroup} from '@angular/forms';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';

@Component({
    selector: 'pagination',
    templateUrl: './pagination.component.html'
})
export class PaginationComponent extends BaseComponent implements OnChanges {
    
    //--1-based page number
    @Input()
    pageNumber: number = 1;

    @Input()
    pageSize: number = 20;

    @Input()
    totalResultsCount: number;

    @Input()
    pageSizeOptions: number[] = [20, 50, 100];

    @Output()
    pageSelected = new EventEmitter<number>();

    @Output()
    pageSizeUpdated = new EventEmitter<number>();

    maxPageNumber: number;
    chosenPageSize: number;
    visiblePageNumbers: number[];

    ngOnChanges(changes: SimpleChanges): void {
        const pageSizeOptions = changes.pageSizeOptions != null && changes.pageSizeOptions != undefined 
            ? changes.pageSizeOptions.currentValue 
            : this.pageSizeOptions
        const pageSize = changes.pageSize != null && changes.pageSize != undefined 
            ? changes.pageSize.currentValue 
            : this.pageSize;

        if(pageSizeOptions.indexOf(pageSize) === -1) {
            this.pageSize = pageSizeOptions[0];
        }

        this.chosenPageSize = pageSize;

        const totalResultsCount = changes.totalResultsCount != null && changes.totalResultsCount != undefined 
            ? changes.totalResultsCount.currentValue 
            : this.totalResultsCount;
        this.maxPageNumber = Math.ceil(totalResultsCount / pageSize);

        const pageNumber = changes.pageNumber != null && changes.pageNumber != undefined 
            ? changes.pageNumber.currentValue 
            : this.pageNumber;
        this.visiblePageNumbers = this.getVisiblePageNumbers(pageNumber);
    }

    getDisplayFrom() {
        // No results.
        if (this.totalResultsCount == 0) {
            return 0;
        }

        // The first page always starts at 1.
        if (this.pageNumber === 1) {
            return 1;
        }

        // Any page greater than 1.
        return this.pageSize * (this.pageNumber - 1) + 1;
    }

    getDisplayTo() {
        // If not the last page.
        if (this.pageNumber < this.maxPageNumber) {
            return this.pageSize * this.pageNumber;
        }

        // On the last page.
        return this.totalResultsCount;
    }

    onPageNumberClicked(pageNumber: number): boolean {
        if(pageNumber === this.pageNumber //--Already on that page
            || pageNumber <= 0 //--Or out of bounds
            || pageNumber > this.maxPageNumber) {            
            return;
        }

        setTimeout(() => this.pageSelected.emit(pageNumber));
        return false;
    }

    onPageSizeChange(pageSize: number): void {
        if(pageSize === this.pageSize) {
            //--Value did not change
            return;
        }

        setTimeout(() => this.pageSizeUpdated.emit(pageSize));
    }

    hasPages(): boolean {
        return this.visiblePageNumbers != null
            && this.visiblePageNumbers != undefined
            && this.totalResultsCount > 0;
    }

    private getVisiblePageNumbers(currentPageNumber: number): number[] {
        var num = this.maxPageNumber;
        if (num <= 2) { return []; }//no pages needed until more then 2

        var arr = [];

        //start with current page (min page 2, max maxpages-1)
        var startPage = Math.min(Math.max(2, currentPageNumber), num - 1);
        arr.push(startPage);

        //add page before and after
        if (startPage - 1 > 1) arr.unshift(startPage - 1);
        if (startPage + 1 < num) arr.push(startPage + 1);

        //check if ... can be replaced with numbers
        if (arr[0] === 3) arr.unshift(2);
        if (arr[arr.length - 1] === num - 2) arr.push(num - 1);

        return arr;
    }
}

