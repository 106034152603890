import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormsModule, FormGroup, FormControl, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { AuthenticationService } from './../../../services/authentication.service';
import { FormBaseComponent } from './../../shared/form-base.component';
import { CurrentUserService } from "../../../services/current-user.service";

@Component({
    selector: 'login',
    templateUrl: './login.component.html'
})
export class LoginComponent extends FormBaseComponent implements OnInit, OnDestroy {
    errorMessage: string;
    constructor(private router: Router,
        private readonly authService: AuthenticationService,
        private currentUserService: CurrentUserService) {
        super();
    }


    ngOnInit(): void {
        //init form
        this.form = new FormGroup({
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required)
        });
    }

    submitForm(): void {
        this.formSubmitted = true;
        this.errorMessage = "";
        if (this.form.valid) {
            this.authService.login(this.form.value.username, this.form.value.password)
                .subscribe(
                (success: any) => {
                    if (this.currentUserService.isLoggedIn()) {
                        this.router.navigate(['./admin']);
                    }
                    else {
                        this.errorMessage = "Unable to validate your credentials.";
                    }
                },
                (error: any) => {
                    this.errorMessage = this.readErrorResponse(error);
                }
                );
        }
    }


    ngOnDestroy(): void {
    }
}
