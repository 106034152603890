import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { map, filter, flatMap } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { options as toastrOptions } from 'toastr';

@Component({
    selector: 'app',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    constructor(private router: Router, private titleService: Title) {
    }

    ngOnInit(): void {
        this.pageTitle();
        this.alertsSetup();

    }

    ngOnDestroy(): void {
    }

    pageTitle(): void {
        this.titleService.setTitle("Statewide Misdemeanant Confinement Program");
        //update page title on route change
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd),
            map(_ => this.router.routerState.root),
            map(route => {//get the deepest title
                while (route.firstChild)
                    route = route.firstChild;
                return route;
            }),
            flatMap(route => route.data)
            )
            .subscribe(data => {
                let title = data['title'];

                if (title) {
                    title += " | SMCP";
                } else {
                    title = "Statewide Misdemeanant Confinement Program";
                }
                this.titleService.setTitle(title);
            });
    }

    alertsSetup(): void {
        toastrOptions.progressBar = true;
        toastrOptions.closeButton = true;
        toastrOptions.newestOnTop = true;
        toastrOptions.positionClass = 'toast-top-right';
        toastrOptions.preventDuplicates = true;
    }

}

