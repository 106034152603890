import { Component } from '@angular/core';
import { BaseComponent } from './../../shared/base.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'not-found',
    templateUrl: './not-found.component.html'
})
export class NotFoundComponent extends BaseComponent {
    constructor(private router: Router,
                private readonly route: ActivatedRoute) {
        super();
    }

    goBack(event: any): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }
}
