import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, FormGroup, FormControl, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { AuthenticationService } from './../../../services/authentication.service';
import { BaseComponent } from './../../shared/base.component';


@Component({
    selector: 'logout',
    templateUrl: './logout.component.html'
})
export class LogoutComponent extends BaseComponent implements OnInit, OnDestroy {
    constructor(private router: Router, private readonly authService: AuthenticationService) {
        super();
    }

    ngOnInit(): void {
        this.authService.logout();
        this.router.navigate(['./']);
    }

    ngOnDestroy(): void {
    }
}
