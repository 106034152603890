import { Injectable } from '@angular/core';
import { UserRole } from '../models/authentication/roles.enum';
import { RoleAuthGuard } from './role-auth.guard';
import { Router } from '@angular/router';
import { CurrentUserService } from '../services/current-user.service';

@Injectable()
export class AdminAuthGuard extends RoleAuthGuard {

    constructor(protected router: Router, protected currentUserService: CurrentUserService) {
        super(router, currentUserService);
    }
    
    getAllowedRoles(): string[] {
        return [UserRole.Admin];
    }
}