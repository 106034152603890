import { Component } from "@angular/core";
import { BaseComponent } from "./../../shared/base.component";
import { LayoutService } from "../../../services/layout.service";

@Component({
    selector: "unauthenticated-section",
    templateUrl: "./unauthenticated-section.component.html",
    styleUrls: ["./unauthenticated-section.component.scss"]
})
export class UnauthenicatedSectionComponent extends BaseComponent {
    constructor(private layoutService: LayoutService) {
        super();
    }

    routeChange(): void {
        //reset show login state on each route change - happens before child component ngOnInit
        this.layoutService.globalAlert(null);
        window.scrollTo(0, 0);
    }
}
