import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

// Public
import { HomeComponent } from "../components/public/home/home.component";
import { LoginComponent } from "../components/public/login/login.component";
import { LogoutComponent } from "../components/public/login/logout.component";
import { ForgotPasswordComponent } from "../components/public/forgot-password/forgot-password.component";
import { ForgotPasswordConfirmComponent } from "../components/public/forgot-password/forgot-password-confirm.component";
import { NotFoundComponent } from "../components/public/errors/not-found.component";
import { AccessDeniedComponent } from '../components/public/errors/access-denied.component';
import { UnauthenicatedSectionComponent } from '../components/public/home/unauthenticated-section.component';

//guards
import { AdminAuthGuard } from "../guards/admin-auth.guard";
import { AuthenticatedAuthGuard } from '../guards/authenticated-auth.guard';

@NgModule({
    imports: [
        RouterModule.forRoot([
            { path: "admin", canActivate: [AuthenticatedAuthGuard], loadChildren: () => import('./admin-section.module').then(m => m.AdminSectionModule) },
            {
                path: "",
                component: UnauthenicatedSectionComponent,
                children: [
                    { path: "", component: HomeComponent, data: { title: null } },
                    { path: "login", component: LoginComponent, data: { title: 'Login' } },
                    { path: "logout", component: LogoutComponent, data: { title: 'Logout' } },
                    { path: "resetpassword", component: ForgotPasswordComponent, data: { title: 'Reset Password' } },
                    { path: "resetpassword/:resetToken", component: ForgotPasswordConfirmComponent, data: { title: 'Reset Password' } },
                    { path: "access-denied", component: AccessDeniedComponent, data: { title: 'Access Denied' } },
                    { path: "**", component: NotFoundComponent, data: { title: 'Page Not Found' } },
                ]
            }
        ])
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AdminAuthGuard,
        AuthenticatedAuthGuard
    ]
})
export class AppRoutingModule { }