import { Injectable } from '@angular/core';
import { JwtHelperService } from './../plugins/angular2-jwt';

import { ITokenDetails } from './../models/authentication/token-details.model';

@Injectable()
export class CurrentUserService {
    jwtHelper: JwtHelperService = new JwtHelperService();
    private tokenStorageKey = "currentUser";
    private currentToken: ITokenDetails;
    private decodedValues: any;

    constructor() {
        var loadedToken = JSON.parse(localStorage.getItem(this.tokenStorageKey)) as ITokenDetails;
        this.currentToken = loadedToken;
        if (loadedToken && loadedToken.token) {
            this.decodedValues = this.jwtHelper.decodeToken(this.currentToken.token);
        } else {
            this.decodedValues = null;
        }
    }    

    setToken(token: ITokenDetails): void {
        this.currentToken = token;
        this.decodedValues = this.jwtHelper.decodeToken(this.currentToken.token);
        localStorage.setItem(this.tokenStorageKey, JSON.stringify(token));
    }

    clearToken(): void {
        this.currentToken = null;
        this.decodedValues = null;
        localStorage.removeItem(this.tokenStorageKey);
    }

    getToken(): ITokenDetails {
        return this.currentToken;
    }

    isLoggedIn(): boolean {
        return !!(this.currentToken && this.currentToken.token);
    }

    userId(): string {
        return this.decodedValues["sub"];
    }    

    isInRole(role: string): boolean {
        var roles = this.decodedValues["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        return  roles 
            && roles.includes
            && typeof roles.includes === "function"
            && roles.includes(role);
    }

}