import { Component} from '@angular/core';
import { BaseComponent } from './../base.component';

@Component({
    selector: 'header-section',
    templateUrl: './header-section.component.html',
    styleUrls: ['./header-section.component.scss']
})
export class HeaderSectionComponent extends BaseComponent {

    constructor() {
        super();
    }

}

