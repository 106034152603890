import { Component, OnInit, OnDestroy } from "@angular/core";
import { Observable } from "rxjs";
import { BaseComponent } from "./../../shared/base.component";

@Component({
    selector: "home",
    templateUrl: "./home.component.html"
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {
    constructor() {
        super();
    }

    ngOnInit() {

    }

    ngOnDestroy(): void {}
}
