import { Injectable, Inject } from "@angular/core";
import { BaseService } from './base.service';
import { Observable } from "rxjs";
import { IError } from './../models/error.model';
import { HttpClient, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ICountyDetails } from "../models/county/county-details.model";
import { ICountySummary } from "../models/county/county-summary.model";
import { ICountyAvailability } from "../models/county/county-availability.model";
import { IBedAllocationMetrics } from "../models/county/bed-allocation-metrics.model";
import { IControlData } from "../models/control-data/control-data.model";

@Injectable()
export class ControlDataService {
    private baseUrl: string = '/api/controldata';

    constructor(private baseService: BaseService) {
        this.baseUrl = `${baseService.baseApi}${this.baseUrl}`;

    }

    getAll(): Observable<IControlData[]> {
        let request = this.baseService.get<IControlData[]>(`${this.baseUrl}`);
        return request;
    }

    getActive(): Observable<IControlData[]> {
        let request = this.baseService.get<IControlData[]>(`${this.baseService.baseApi}/api/currentActiveControlData`);
        return request;
    }

    get(id: string): Observable<IControlData> {
        let request = this.baseService.get<IControlData>(`${this.baseUrl}/${id}`);
        return request;
    }

    delete(id: string): Observable<void> {
        let request = this.baseService.delete(`${this.baseUrl}/${id}`);
        return request;
    }

    update(data: IControlData): Observable<IControlData> {
        let request = this.baseService.put<IControlData>(`${this.baseUrl}/${data.id}`, data);
        return request;
    }

    create(data: IControlData): Observable<IControlData> {
        let request = this.baseService.post<IControlData>(`${this.baseUrl}`, data);
        return request;
    }
}