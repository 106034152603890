import { Component, OnInit, Input } from "@angular/core";
import { BaseComponent } from "../base.component";

@Component({
    selector: 'phone-number',
    templateUrl: './phone-number.component.html'
})
export class PhoneNumberComponent extends BaseComponent implements OnInit {

    @Input()
    phoneNumber: String;

    maskedPhoneNumber: String;

    ngOnInit(): void {
        if (!this.phoneNumber || this.phoneNumber.length !== 10) {
            this.maskedPhoneNumber = this.phoneNumber;
            return;
        }

        this.maskedPhoneNumber = `(${this.phoneNumber.substring(0,3)}) ${this.phoneNumber.substring(3,6)}-${this.phoneNumber.substring(6)}`;
    }
}

