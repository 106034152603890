import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseComponent } from './../base.component';

@Component({
    selector: 'footer-section',
    templateUrl: './footer-section.component.html',
    styleUrls: ['./footer-section.component.scss']
})
export class FooterSectionComponent extends BaseComponent {

    constructor() {
        super();
    }

}

