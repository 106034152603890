import { Injectable, Inject } from "@angular/core";
import { BaseService } from './base.service';
import { CurrentUserService } from './current-user.service';
import { Observable, Subject } from "rxjs";
import { ITokenDetails } from './../models/authentication/token-details.model';
import { IError } from './../models/error.model';
import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';

@Injectable()
export class AuthenticationService {
    private baseUrl: string = '/api/authentication';

    constructor(private baseService: BaseService, private currentUserService: CurrentUserService) {
        this.baseUrl = `${baseService.baseApi}${this.baseUrl}`;
    }

    login(username: string, password: string): Observable<ITokenDetails> {
        let request = this.baseService.post<ITokenDetails>(`${this.baseUrl}/token`, { username: username, password: password });
        request.subscribe(
            // store username and jwt token in local storage to keep user logged in between page refreshes
            (response: ITokenDetails) => { this.currentUserService.setToken(response); },
            (error: any) => { this.logout(); }
        );
        return request;
    }

    refresh(refreshToken: string): Observable<ITokenDetails> {
        let request = this.baseService.post<ITokenDetails>(`${this.baseUrl}/refresh`, { refreshToken: refreshToken });
        request.subscribe(
            // store username and jwt token in local storage to keep user logged in between page refreshes
            (response: ITokenDetails) => { this.currentUserService.setToken(response); },
            (error: any) => { this.logout(); }
        );
        return request;
    }

    logout(): void {
        // clear token remove user from local storage to log user out
        this.currentUserService.clearToken();
    }

    resetPassword(emailAddress: string): Observable<void> {
        let request = this.baseService.post<void>(`${this.baseUrl}/resetPassword`, { emailAddress: emailAddress });
        return request;
    }

    resetPasswordConfirm(emailAddress: string, newPassword: string, resetToken: string): Observable<void> {
        let request = this.baseService
            .post<void>(`${this.baseUrl}/resetPasswordConfirm`, { emailAddress: emailAddress, newPassword: newPassword, resetToken: resetToken });
        return request;
    }

}