import { Injectable } from '@angular/core';
import { UserRole } from '../models/authentication/roles.enum';
import { RoleAuthGuard } from './role-auth.guard';
import { Router, CanActivate } from '@angular/router';
import { CurrentUserService } from '../services/current-user.service';

@Injectable()
export class AuthenticatedAuthGuard implements CanActivate {

    constructor(protected router: Router, protected currentUserService: CurrentUserService) {
    }
    
    canActivate() {

        if (!this.currentUserService.isLoggedIn()) {
            // not logged in so redirect to login page
            this.router.navigate(['/']);
            return false;
        }

        return true;
    }
}