import { Component, Input, EventEmitter, Output, OnInit } from "@angular/core";
import { BaseComponent } from "../base.component";

@Component({
    selector: 'sort-toggle',
    templateUrl: './sort-toggle.component.html'
})
export class SortToggleComponent extends BaseComponent implements OnInit {

    @Input()
    label: string;

    @Input()
    propName: string;

    @Input()
    asc: boolean = false;

    @Input()
    selected: string;

    @Output()
    sortToggled = new EventEmitter<{ propName: string, asc: boolean, sortId: string }>();

    id: string;

    ngOnInit(): void {
        this.id = ""+(Math.random().toString()).substring(2);//random id for tracking
    }

    toggleSort(): void {
        if (this.id != this.selected) {
            this.asc = true;
        }
        else {
            this.asc = !this.asc;
        }
        this.sortToggled.emit({ propName: this.propName, asc: this.asc, sortId: this.id });
    }
}

