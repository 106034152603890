import { Component, OnInit, OnDestroy } from '@angular/core';
import { LayoutService } from "../../../services/layout.service";
import { Observable, of } from "rxjs";

@Component({
    selector: 'alert',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit, OnDestroy  {
    message: Observable<string>;
    type: string;

    constructor(private layoutService: LayoutService) {
        this.message = of('');
    }

    ngOnInit(): void {
        this.message = this.layoutService.globalAlert$;
    }

    ngOnDestroy(): void {
    }
}

