import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Subject } from "rxjs";
import { share } from "rxjs/operators";

import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';

import { LayoutService } from "./layout.service";
import { environment } from "./../../environments/environment";

@Injectable()
export class BaseService {
    private currentCall: Observable<any>;

    get baseApi() {
        return environment.API_URL;
    }

    constructor(private http: HttpClient, private layoutService: LayoutService) {
    }

    get<T>(url: string): Observable<T> {

        this.layoutService.loading(true);
        this.currentCall = this.http.get<T>(url).pipe(share());
        this.currentCall.subscribe(
            () => { this.layoutService.loading(false); },
            () => { this.layoutService.loading(false); },
            () => { this.layoutService.loading(false); }
        );
        return this.currentCall;
    }

    blob(url: string): Observable<any> {

        this.layoutService.loading(true);
        this.currentCall = this.http.get(url, { responseType: 'blob' }).pipe(share());
        this.currentCall.subscribe(
            () => { this.layoutService.loading(false); },
            () => { this.layoutService.loading(false); },
            () => { this.layoutService.loading(false); }
        );
        return this.currentCall;
    }

    post<T>(url: string, postData: any): Observable<T> {
        this.layoutService.loading(true);
        this.currentCall = this.http.post<T>(url, postData).pipe(share());
        this.currentCall.subscribe(
            () => { this.layoutService.loading(false); },
            () => { this.layoutService.loading(false); },
            () => { this.layoutService.loading(false); }
        );
        return this.currentCall;
    }

    put<T>(url: string, putData: any): Observable<T> {
        this.layoutService.loading(true);
        this.currentCall = this.http.put<T>(url, putData).pipe(share());
        this.currentCall.subscribe(
            () => { this.layoutService.loading(false); },
            () => { this.layoutService.loading(false); },
            () => { this.layoutService.loading(false); }
        );
        return this.currentCall;
    }

    patch<T>(url: string, putData: any): Observable<T> {
        this.layoutService.loading(true);
        this.currentCall = this.http.patch<T>(url, putData).pipe(share());
        this.currentCall.subscribe(
            () => { this.layoutService.loading(false); },
            () => { this.layoutService.loading(false); },
            () => { this.layoutService.loading(false); }
        );
        return this.currentCall;
    }

    head<T>(url: string): Observable<T> {
        this.layoutService.loading(true);
        this.currentCall = this.http.head<T>(url).pipe(share());
        this.currentCall.subscribe(
            () => { this.layoutService.loading(false); },
            () => { this.layoutService.loading(false); },
            () => { this.layoutService.loading(false); }
        );
        return this.currentCall;
    }

    delete(url: string): Observable<any> {
        this.layoutService.loading(true);
        this.currentCall = this.http.delete(url).pipe(share());
        this.currentCall.subscribe(
            () => { this.layoutService.loading(false); },
            () => { this.layoutService.loading(false); },
            () => { this.layoutService.loading(false); }
        );
        return this.currentCall;
    }
}