import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, FormGroup, FormControl, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { ActivatedRoute, ParamMap } from "@angular/router";
import { AuthenticationService } from './../../../services/authentication.service';
import { FormBaseComponent } from './../../shared/form-base.component';
import { ValidationService } from "../../../services/validation.service";

@Component({
    selector: 'forgot-password-confirm',
    templateUrl: './forgot-password-confirm.component.html'
})
export class ForgotPasswordConfirmComponent extends FormBaseComponent  implements OnInit {
    errorMessage: string;
    resetToken: string;
    success: boolean;

    constructor(private router: Router, 
        private readonly route: ActivatedRoute, 
        private readonly authService: AuthenticationService, 
        private validationService: ValidationService) {
        super();
        this.success = false;
    }

    ngOnInit(): void {
        this.resetToken = this.route.snapshot.paramMap.get('resetToken');

        //init form
        this.form = new FormGroup({
            emailAddress: new FormControl('', Validators.required),
            newPassword: new FormControl('', Validators.required),
            newPasswordConfirm: new FormControl('', Validators.required)
        }, this.validationService.areTheSame('newPassword', 'newPasswordConfirm', 'passwordsNotSame'));
    }

    submitForm(): void {
        this.formSubmitted = true;
        this.errorMessage = "";
        if (this.form.valid) {
            this.form.disable();
            this.authService.resetPasswordConfirm(this.form.value.emailAddress, this.form.value.newPassword, this.resetToken)
                .subscribe(
                    (success: void) => {
                        this.form.enable();
                        this.success = true;
                    },
                    (error: any) => {
                        this.form.enable();
                        this.errorMessage = this.readErrorResponse(error);
                    }
                );
        }
    }

    goToLogin(): void {
        this.router.navigate(['/login']);
    }
}
